//
// dropzone.js
// Theme module
//

import Dropzone from 'dropzone';

Dropzone.autoDiscover = false;
Dropzone.thumbnailMethod = 'contain';
Dropzone.thumbnailWidth = null;
Dropzone.thumbnailHeight = 240;
Dropzone.addRemoveLinks = true;

const toggles = document.querySelectorAll('[data-dropzone]');

toggles.forEach((toggle) => {
  let currentFile = undefined;
  let acceptedFiles = toggle.dataset.dropzoneAcceptedFiles || 'image/*';
  let maxFileSize = toggle.dataset.dropzoneMaxFileSize || 2;
  let resizeWidth = toggle.dataset.dropzoneResizeWidth || null;
  //var thumbnail = toggle.dataset.dropzoneThumbnail || null;  
  var messageContainer = toggle.querySelector('.dz-default');
  const elementOptions = toggle.dataset.dropzone ? JSON.parse(toggle.dataset.dropzone) : {};

  const defaultOptions = {
    previewsContainer: toggle.querySelector('.dz-preview'),
    previewTemplate: toggle.querySelector('.dz-preview').innerHTML,    
    init: function () {
      this.on('addedfile', function (file) {
        const maxFiles = elementOptions.maxFiles;
        if (maxFiles == 1 && currentFile) {
          this.removeFile(currentFile);
        }
        currentFile = file;
        //messageContainer.hide();
      });
      this.on("error", function (file, message) {
          this.removeFile(file);
          alert(message.message);
        }),
      this.on("removedfile", function (file) {
        $.post({
          url: elementOptions.removeUrl || '',
          data: {
            id: file.name,
            _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          dataType: 'json'
        });
      });
      // Fetch existing images
      /*var thisDropzone = this;
      $.post({
        url: elementOptions.url || '',
        data: {
          request: 'fetch',
          _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        dataType: 'json',
        success: function(response) {
          $.each(response, function(key,value) {
            var mockFile = { name: value.name, size: value.size};
            thisDropzone.emit("addedfile", mockFile);
            thisDropzone.emit("thumbnail", mockFile, value.path);
            thisDropzone.emit("success", mockFile, null, null);
            thisDropzone.emit("complete", mockFile);
            toggle.querySelector('.dz-default').hide();
          });
        }
      });*/
    },
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    addRemoveLinks: typeof elementOptions.removeUrl !== 'undefined',
    acceptedFiles: 'image/*',
    dictRemoveFile: "Rimuovi il file",
    thumbnailHeight: 240
  };

  const options = {
    ...elementOptions,
    ...defaultOptions,
  };

  // Clear preview
  toggle.querySelector('.dz-preview').innerHTML = '';

  // Init dropzone
  new Dropzone(toggle, options);
});

// Make available globally
window.Dropzone = Dropzone;